@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}



.container {
  margin: 0 auto;
  margin-bottom: 50px;
  flex: auto;
  background-color:#EEEEEE;
}

.header {
    flex-direction: row;
    color: black;
    background-color: white;
    align-items: center;
   }

   .containerCard {
    margin-top: 30px;
    width: 34rem;
  }

.scrollableTimeline {
    margin-top: 20px;
    max-height: 250px;
    overflow-y: auto;
}

.notification {
  margin: 5px 25px;
}

.cardAction {
  vertical-align: 50%;
  float: right;
  margin-right: 5px;
  color: grey;
}

.cardAction:hover
{
  color: cornflowerblue;
  cursor: pointer;
}

.dateSmall {
  color: cornflowerblue;
  background-color: rgb(235, 232, 232);
  padding: 3px;
}

.Logo{
  margin-top: 15px;
  margin-left: 20px;;
  max-width:90px;

  max-height:65px;
}

.spinner {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}


.antd-icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.buzon-container {
  background-color: "white";
  width: 100%;
  height: 500px;
}

.botonNuevaConversacion{
  float: 'right';
  margin-left: "85%";
}

.buzon-message-sent {
  background-color: rgb(242, 247, 229);
  margin-bottom: 10px;
  margin-left: auto;
  width: 70%;
  margin-right: 20px;
  padding-left: 5px;
}

.buzon-message-received {
  background-color: rgb(163, 215, 255);
  margin-left: 20px;
  margin-bottom: 10px;
  width: 70%;
  padding-left: 5px;
}

/*+++++++++++++ pagina de sintomas +++++++*/
.tablaSintomas{
  width: 100%;
  margin-top: 35px;
}